<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toast />
        <Toolbar class="mb-4">
          <template v-slot:start>
            <div class="my-2">
              <Button
                label="New"
                icon="pi pi-plus"
                class="p-button-success mr-2"
                @click="
                  () => {
                    showModalCreate = true;
                    form = {};
                  }
                "
              />
              <Button
                label="Delete"
                icon="pi pi-trash"
                class="p-button-danger"
                @click="confirmDeleteSelected"
                :disabled="!selectedUsers || !selectedUsers.length"
              />
            </div>
          </template>
        </Toolbar>

        <DataTable
          ref="dt"
          :value="users"
          v-model:selection="selectedUsers"
          dataKey="_id"
          :paginator="true"
          :rows="10"
          :filters="filters"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} items"
          responsiveLayout="scroll"
        >
          <template #header>
            <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
              <h5 class="m-0">User List</h5>
              <span class="block mt-2 md:mt-0 p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filters['global'].value" placeholder="Search..." />
              </span>
            </div>
          </template>

          <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
          <Column field="username" header="Username" :sortable="true">
            <template #body="slotProps">
              {{ slotProps.data.username }}
            </template>
          </Column>
          <Column field="email" header="Email" :sortable="true">
            <template #body="slotProps">
              {{ slotProps.data.email }}
            </template>
          </Column>

          <Column field="name" header="Role" :sortable="true">
            <template #body="slotProps">
              {{ slotProps.data.role_id && slotProps.data.role_id.name }}
            </template>
          </Column>
          <Column field="created_at" header="Created At" :sortable="true">
            <template #body="slotProps">
              {{ moment(slotProps.data.created_at).format("LL") }}
            </template>
          </Column>
          <Column field="is_active" header="Active" :sortable="true">
            <template #body="slotProps">
              {{ slotProps.data.is_active ? "Active" : "Not Active" }}
            </template>
          </Column>
          <Column headerStyle="min-width:10rem;" header="Actions">
            <template #body="slotProps">
              <Button icon="pi pi-pencil" class="p-button-rounded p-button-warning mr-2" @click="showEditModal(slotProps.data)" />
              <Button
                icon="pi pi-trash"
                class="p-button-rounded p-button-danger mt-2"
                @click="confirmDeleteItem(slotProps.data)"
              />
            </template>
          </Column>
        </DataTable>

        <!-- create -->
        <Dialog v-model:visible="showModalCreate" :style="{ width: '450px' }" header="Add User" :modal="true" class="p-fluid">
          <div class="field">
            <label for="username">Username :</label>
            <InputText id="username" v-model.trim="form.username" required="true" autofocus />
          </div>
          <div class="field">
            <label for="email">Email :</label>
            <InputText id="email" v-model.trim="form.email" required="true" autofocus />
          </div>
          <div class="field">
            <label for="password">Password :</label>
            <Password id="password" :toggleMask="true" v-model.trim="form.password" required="true" autofocus />
          </div>

          <div class="field">
            <label for="inventoryStatus" class="mb-3">Role :</label>
            <Dropdown
              id="inventoryStatus"
              v-model="form.role_id"
              :options="roles_option"
              optionLabel="label"
              placeholder="Select a Status"
            >
              <template #value="slotProps">
                <div v-if="slotProps.value && slotProps.value.value">
                  <span :class="'product-badge status-' + slotProps.value.value">{{ slotProps.value.label }}</span>
                </div>
                <div v-else-if="slotProps.value && !slotProps.value.value">
                  <span :class="'product-badge status-' + slotProps.value.toLowerCase()">{{ slotProps.value }}</span>
                </div>
                <span v-else>
                  {{ slotProps.placeholder }}
                </span>
              </template>
            </Dropdown>
          </div>

          <div class="field">
            <label for="inventoryStatus" class="mb-3">Active Status (optional default is not active) :</label>
            <Dropdown
              id="inventoryStatus"
              v-model="form.is_active"
              :options="active_option"
              optionLabel="label"
              placeholder="Select a Status"
            >
              <template #value="slotProps">
                <div v-if="slotProps.value && slotProps.value.value">
                  <span :class="'product-badge status-' + slotProps.value.value">{{ slotProps.value.label }}</span>
                </div>
                <div v-else-if="slotProps.value && !slotProps.value.value">
                  <span :class="'product-badge status-' + slotProps.value.toLowerCase()">{{ slotProps.value }}</span>
                </div>
                <span v-else>
                  {{ slotProps.placeholder }}
                </span>
              </template>
            </Dropdown>
          </div>

          <template #footer>
            <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="showModalCreate = false" />
            <Button
              :label="isLoading ? 'Creating' : 'Create'"
              icon="pi pi-check"
              class="p-button-text"
              :disabled="!form.role_id"
              :loading="isLoading"
              @click="createUser"
            />
          </template>
        </Dialog>

        <!-- Edit Modal Item -->
        <Dialog v-model:visible="showModalEdit" :style="{ width: '450px' }" header="Edit User" :modal="true" class="p-fluid">
          <div class="field">
            <label for="username">Username :</label>
            <InputText id="username" v-model.trim="form.username" required="true" autofocus />
          </div>
          <div class="field">
            <label for="email">Email :</label>
            <InputText id="email" v-model.trim="form.email" required="true" autofocus disabled />
          </div>
          <!-- <div class="field">
            <label for="email">New Password (optional) :</label>
            <Password
              :toggleMask="true"
              id="password"
              v-model.trim="new_password"
              required="true"
              autofocus
            />
          </div> -->
          <div class="field">
            <label for="inventoryStatus" class="mb-3">Role :</label>
            <Dropdown
              id="inventoryStatus"
              v-model="form.role_id"
              :options="roles_option"
              optionLabel="label"
              placeholder="Select a Status"
            >
              <template #value="slotProps">
                <div v-if="slotProps.value && slotProps.value.value">
                  <span :class="'product-badge status-' + slotProps.value.value">{{ slotProps.value.label }}</span>
                </div>
                <div v-else-if="slotProps.value && !slotProps.value.value">
                  <span>{{ slotProps.value }}</span>
                </div>
                <span v-else>
                  {{ slotProps.placeholder }}
                </span>
              </template>
            </Dropdown>
          </div>

          <div class="field">
            <label for="inventoryStatus" class="mb-3">membership :</label>
            <Dropdown
              id="inventoryStatus"
              v-model="form.membership_id"
              :options="voucher_options"
              optionLabel="label"
              placeholder="Select a Status"
            >
              <template #value="slotProps">
                <div v-if="slotProps.value && slotProps.value.value">
                  <span :class="'product-badge status-' + slotProps.value.value">{{ slotProps.value.label }}</span>
                </div>
                <div v-else-if="slotProps.value && !slotProps.value.value">
                  <span>{{ slotProps.value.label }}</span>
                </div>
                <span v-else>
                  {{ slotProps.placeholder }}
                </span>
              </template>
            </Dropdown>
          </div>

          <div class="field">
            <label for="inventoryStatus" class="mb-3">Active status :</label>
            <Dropdown
              id="inventoryStatus"
              v-model="form.is_active"
              :options="active_option"
              optionLabel="label"
              placeholder="Select a Status"
            >
              <template #value="slotProps">
                <div v-if="slotProps.value && slotProps.value.value">
                  <span :class="'product-badge status-' + slotProps.value.value">{{ slotProps.value.label }}</span>
                </div>
                <div v-else-if="slotProps.value && !slotProps.value.value">
                  <span>{{ slotProps.value }}</span>
                </div>
                <span v-else>
                  {{ slotProps.placeholder }}
                </span>
              </template>
            </Dropdown>
          </div>

          <template #footer>
            <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="showModalEdit = false" />
            <Button
              :label="isLoading ? 'Updating' : 'Update'"
              icon="pi pi-check"
              class="p-button-text"
              :loading="isLoading"
              @click="editUser"
            />
          </template>
        </Dialog>

        <!-- Delete Modal Item -->
        <Dialog v-model:visible="showModalDeleteItem" :style="{ width: '450px' }" header="Delete User Confirm ?" :modal="true">
          <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span
              >Are you sure you want to delete <b>{{ temp_user.username }}</b> ?</span
            >
          </div>
          <template #footer>
            <Button @click="showModalDeleteItem = !showModalDeleteItem" label="No" icon="pi pi-times" class="p-button-text" />
            <Button
              label="Yes"
              icon="pi pi-check"
              class="p-button-text"
              :loading="isLoading"
              @click="deleteItem(temp_user._id)"
            />
          </template>
        </Dialog>

        <!-- Delete Modal Multiple -->
        <Dialog v-model:visible="showModalDelete" :style="{ width: '450px' }" header="Delete User Confirm ?" :modal="true">
          <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span>Are you sure you want to delete the selected User?</span>
          </div>
          <template #footer>
            <Button label="No" icon="pi pi-times" class="p-button-text" @click="showModalDelete = false" />
            <Button label="Yes" icon="pi pi-check" class="p-button-text" :loading="isLoading" @click="deleteMultipleUser" />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import UserApi from "../../api/User";
import RoleApi from "../../api/Role";
import VoucherApi from "../../api/Voucher";
import jwtDecode from "jwt-decode";

import moment from "moment";

export default {
  data() {
    return {
      moment: moment,
      showModalCreate: false,
      showModalDelete: false,
      showModalDeleteItem: false,
      showModalEdit: false,
      selectedRole: {},
      deleteProductDialog: false,
      users: [],
      voucher_options: [{ label: "deactivate membership", value: null }],
      temp_user: {},
      roles_option: [],
      voucher_list: [],
      selectedUsers: [],
      isLoading: false,
      form: {
        username: "",
        email: "",
        password: "",
        role_id: "",
        membership_id: "",
      },
      new_password: "",
      active_option: [
        { label: "Active", value: true },
        { label: "Inactive", value: false },
      ],
    };
  },
  productService: null,
  async created() {
    this.initFilters();
    await this.getVoucher();
  },
  async mounted() {
    await this.getRoles();
    await this.getUsers();
  },

  methods: {
    async getRoles() {
      try {
        const response = await RoleApi.Get();
        if (response.data.status === 200) {
          let role_options = [];
          const current_user_data = jwtDecode(this.$cookies.get("token"));

          response.data.data.map((role) => {
            role_options.push({ label: role.name, value: role._id });
          });

          console.log(process.env.VUE_APP_SUPER_ADMIN_ID);
          if (current_user_data?.role_id?._id !== process.env.VUE_APP_SUPER_ADMIN_ID) {
            this.roles_option = role_options.filter((role) => role.label !== "super_admin");
          } else {
            this.roles_option = role_options;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getUsers() {
      try {
        const response = await UserApi.Get();
        if (response.data.status === 200) {
          let voucher_options = this.voucher_options.filter((el) => el.value);
          this.users = response.data.data.map((el) => {
            if (el.membership_id) {
              let find_membership = voucher_options.find((elem) => elem.value.toString() == el.membership_id.toString());
              if (find_membership) {
                el["membership_id"] = find_membership;
              }
            }
            return el;
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getVoucher() {
      try {
        const response = await VoucherApi.Get();
        if (response.data.status === 200) {
          this.voucher_list = response.data.data.filter((el) => el.voucher_type == 3);
          this.voucher_list.map((voucher) => {
            this.voucher_options.push({
              label: voucher.title,
              value: voucher._id,
            });
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async createUser() {
      this.isLoading = true;
      try {
        const response = await UserApi.Add({
          ...this.form,
          role_id: this.form.role_id.value,
          is_active: this.form.is_active.value,
        });

        if (response.data.status === 200) {
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: "New User Created!",
            life: 3000,
          });

          // Reset Form
          this.form = {
            username: "",
            email: "",
            password: "",
            role_id: "",
          };

          this.getUsers();
          this.isLoading = false;
          this.showModalCreate = false;
        } else {
          this.form = {
            username: "",
            email: "",
            password: "",
            role_id: "",
          };

          this.$toast.add({
            severity: "error",
            summary: "Failed!",
            detail: response.data.message,
            life: 3000,
          });
          this.isLoading = false;
          this.showModalCreate = false;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async showEditModal(user) {
      try {
        this.form = {
          ...user,
          role_id: {
            label: user.role_id ? user.role_id.name : "",
            value: user.role_id ? user.role_id._id : "",
          },
          active_option: {
            label: user.is_active ? user.is_active.name : "",
            value: user.is_active ? user.is_active._id : "",
          },
        };

        this.showModalEdit = true;
      } catch (error) {
        console.log(error);
      }
    },
    async editUser() {
      try {
        this.isLoading = true;
        let submit_data = {
          ...this.form,
          role_id: this.form.role_id?.value,
          is_active: this.form.is_active?.value,
        };
        submit_data["membership_id"] = this.form.membership_id?.value;
        delete submit_data.sign;
        delete submit_data.token_temp;
        delete submit_data.modified_at;
        delete submit_data.created_at;

        const response = await UserApi.Update(this.form._id, submit_data);
        if (response.data.status === 200) {
          this.getUsers();
          this.form = {};
          this.isLoading = false;
          this.showModalEdit = false;

          this.$toast.add({
            severity: "success",
            summary: "Success",
            detail: "User Updated!",
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Update Failed!",
            detail: "Something went wrong",
            life: 3000,
          });
          this.showModalEdit = false;
          this.isLoading = false;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async deleteMultipleUser() {
      this.isLoading = true;
      try {
        //   reset selected users
        let payload = {
          id: [],
        };

        for (let i = 0; i < this.selectedUsers.length; i++) {
          payload.id.push(this.selectedUsers[i]._id);
        }

        const response = await UserApi.DeleteMultiple(payload);
        if (response.data.status === 200) {
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: response.data.message,
            life: 3000,
          });
          this.getUsers();
          this.isLoading = false;
          this.selectedUsers = [];
          this.showModalDelete = false;
        }
      } catch (error) {
        console.log(error);
      }
    },
    confirmDeleteItem(user) {
      this.showModalDeleteItem = true;
      this.temp_user = user;
    },
    async deleteItem(id) {
      try {
        this.isLoading = true;
        const response = await UserApi.Delete(id);

        if (response.data.status === 200) {
          this.isLoading = false;
          this.showModalDeleteItem = false;
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: `${this.temp_user.username} Deleted!`,
            life: 3000,
          });
          this.getUsers();
          this.temp_user = {};
        }
      } catch (error) {
        console.log(error);
      }
    },

    confirmDeleteSelected() {
      this.showModalDelete = true;
    },

    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/demo/badges.scss";
</style>
